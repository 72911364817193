// index.js

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, DatePicker, message } from "antd";

import PatientSearch from "./Components/PatientSearch";
import ColumnFilters from "./Components/ColumnFilters";
import TreatmentTable from "./Components/TreatmentTable";
import UpdatePatient from "./Components/UpdatePatient";
import {
  DropdownCellRenderer,
  CheckboxCellRenderer,
  NoteCellRenderer,
  QuantityCellRenderer,
  CheckboxHeader,
  DateCellRenderer,
  LPDCellRenderer,
  DelDupRenderer,
} from "./Components/CellRenderers";
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
  ReloadOutlined,
  WarningFilled,
} from "@ant-design/icons";
import TreatmentTemplates from "../TreatmentViewDisplay/TreatmentTemplates";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Tooltip,
  IconButton,
  DialogContent,
  TextField,
  Grid,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  Switch,
  CircularProgress,
  DialogActions,
  Autocomplete,
  Skeleton,
} from "@mui/material";
import {
  Add,
  ChevronLeft,
  ChevronRight,
  History,
  HistoryOutlined,
  Print,
  Remove,
  RemoveCircleOutline,
  Warning,
} from "@mui/icons-material";
import { Checkbox as AntdCheckbox } from "antd";
import jsPDF from "jspdf";
import moment from "moment";
import CustomToast from "../../Components/CustomToast";
import { Tooltip as AntdTooltip } from "antd";
import LPDPopup from "../../Components/LPDPopup";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import { common, yellow } from "@mui/material/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const TreatmentView = () => {
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState("all");
  const [allColumnDefs, setAllColumnDefs] = useState({});
  const [template, setTemplate] = useState(null);
  const [openVitalsModal, setOpenVitalsModal] = useState(false);
  const [vitalData, setVitalData] = useState(null);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState({});
  const [historicalSupplements, setHistoricalSupplements] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [openSupplements, setOpenSupplements] = useState(false);
  const [checkedSupplements, setCheckedSupplements] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [customFrequency, setCustomFrequency] = useState({});
  const [isCustomFrequencySelected, setIsCustomFrequencySelected] = useState(
    {}
  );
  const [printOptions, setPrintOptions] = useState({
    recommended: true,
    historical: true,
  });
  const [openPreview, setOpenPreview] = useState(false);
  const pdfDocRef = useRef(null);
  const [selectedPatientSH, setSelectedPatientSH] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loadingSupplementId, setLoadingSupplementId] = useState(null);
  const [matchedSupplement, setMatchedSupplement] = useState({});
  const [toast, setToast] = useState(null);
  const [showNotes, setShowNotes] = useState(true);
  const [showLabs, setShowLabs] = useState(true);
  const [showProcedures, setShowProcedures] = useState(true);
  const [showDispensed, setShowDispensed] = useState(true);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [activeTreatmentId, setActiveTreatmentId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD")
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [updatedEvents, setUpdatedEvents] = useState([]);
  const [checkboxes, setCheckboxes] = useState({
    activeLabs: false,
    activeProcedures: false,
    activeDispensed: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const fetchSupplementNames = async () => {
    try {
      const response = await axios.get(
        `/defaultSupplements`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const supplements = response.data.map((supplement) => {
        if (!supplement.frequency || supplement.frequency.length === 0) {
          supplement.frequency = ["1 dosage twice a day"];
        }
        return supplement;
      });

      setSupplements(supplements);
    } catch (error) {
      console.error("Error fetching supplement names:", error);
      // Handle the error appropriately in your application, e.g., showing an error message
      throw error;
    }
  };

  const controllerRef = useRef(null);

  const handleSearch = async (value) => {
    setHighlightedIndex(0);
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    if (value) {
      try {
        const response = await axios.get(
          `/api/patients/search?query=${value}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
            signal: controller.signal,
          }
        );
        setPatients(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Previous request canceled:", error.message);
        } else {
          console.error("Error fetching patients:", error);
        }
      }
    } else {
      setPatients([]);
    }
  };

  const fetchVitalData = async (patientId) => {
    try {
      const response = await axios.get(
        `/patient/vitals/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      function sortByDateDescending(apiResponse) {
        return apiResponse.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      setVitalData(sortByDateDescending(response.data));
      handleVitalsModalOpen();
    } catch (error) {
      console.error("Error fetching vital data", error);
    }
  };

  const handleSelectPatient = async (patientId) => {
    // setIsLoading(true);
    try {
      const patientResponse = await axios.get(
        `/api/patients/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (patientResponse.status === 200) {
        setSelectedPatient(patientResponse.data);
        setSelectedPatientId(patientResponse.data["Customer ID"]);
        // toastifyToast.success(
        //   `Patient ${patientResponse.data["Full Name"]} selected successfully`,
        //   { toastId: patientResponse.data["Customer ID"] }
        // );

        await fetchTreatments(patientResponse.data["Customer ID"]);
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      toastifyToast.error(`Failed to fetch patient data: ${error.message}`);
      // setIsLoading(false);
    }
  };

  const handleUpdateOrder = async (orderId, updateInfo, newValue) => {
    try {
      const url = `/api/patient-orders/${orderId}`;
      const payload = {
        type: updateInfo.type,
        valueType: updateInfo.valueType,
        name: updateInfo.name,
        value: newValue,
      };

      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log(`Order ${orderId} updated successfully`);
        let type = updateInfo.name || updateInfo.valueType;
        toastifyToast.success(`${type} updated successfully`);
        // Optionally, you can update the local state here if needed
        setOrders((prevState) => {
          return prevState.map((order) => {
            if (order._id === orderId) {
              return { ...order, review: response.data.order.review };
            } else {
              return order;
            }
          });
        });
        await fetchTreatments(selectedPatient["Customer ID"]);
      } else {
        throw new Error(`Failed to update order ${orderId}`);
      }
    } catch (error) {
      console.error("Error updating order:", error);

      // Check if the error response contains the specific message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Error updating order" &&
        error.response.data.error ===
          "You cannot modify this as it has been finalized."
      ) {
        toastifyToast.error(
          "This order has been finalized and cannot be modified.",
          {
            icon: "🔒",
          }
        );
      } else {
        // For all other errors
        toastifyToast.error(
          `Failed to update order: ${
            error.response?.data?.error || error.message
          }`
        );
      }
    }
  };

  const createNewOrder = async (type) => {
    if (!selectedPatient) {
      toastifyToast.error("Please select a patient first");
      return;
    }

    try {
      const response = await axios.post(
        `/api/patient/order/new/${selectedPatient["Customer ID"]}/${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success(`New ${type.toLowerCase()} created successfully`);
        //Append treatment to the row data
        const newRowData = [
          ...rowData,
          {
            orderId: response.data._id,
            date: response.data.date,
            customName: response.data.custom || "",
            type: response.data.type || "Order",
            review: response.data.review || false,
            status: response.data.status || "",
            assignees: response.data.assignees || [],
            room: response.data.room || "",
            note: response.data.note || "",
          },
        ];
        setRowData(newRowData);
        setOrders((prevState) => {
          return [
            { ...response.data, orderId: response.data._id },
            ...prevState,
          ];
        });
      } else {
        throw new Error(`Failed to create new ${type.toLowerCase()}`);
      }
    } catch (error) {
      console.error(`Error creating new ${type.toLowerCase()}:`, error);
      toastifyToast.error(
        `Failed to create new ${type.toLowerCase()}: ${error.message}`
      );
    }
  };

  const handleCellValueChanged = (params) => {
    const orderId = params.data.orderId;
    const columnId = params.column.colId;
    const newValue = params.newValue;

    // Helper function to determine the update type and value type
    const getUpdateInfo = (columnId) => {
      if (columnId.startsWith("procedure_")) {
        const parts = columnId.split("_");
        const valueType = parts[parts.length - 1];
        const procedureName = parts
          .slice(
            1,
            valueType === "note" || valueType === "customDosage"
              ? -1
              : undefined
          )
          .join(" ");
        return {
          type: "userProcedures",
          valueType:
            valueType === "customDosage"
              ? "customDosage"
              : valueType === "note"
              ? "note"
              : typeof newValue === "boolean"
              ? "checkbox"
              : "dosage",
          name: procedureName,
        };
      } else if (columnId.startsWith("lab_")) {
        return {
          type: "userLabs",
          valueType: "checkbox",
          name: columnId.split("_").slice(1).join(" "),
        };
      } else if (columnId.startsWith("dispensed_")) {
        const parts = columnId.split("_");
        const valueType = parts[parts.length - 1];
        const dispensedName = parts
          .slice(
            1,
            valueType === "quantity" || valueType === "note" ? -1 : undefined
          )
          .join(" ");
        return {
          type: "userdispensed",
          valueType:
            valueType === "quantity"
              ? "quantity"
              : valueType === "note"
              ? "note"
              : "dosage",
          name: dispensedName,
        };
      }
      return { type: "common", valueType: columnId };
    };

    const updateInfo = getUpdateInfo(columnId);

    if (updateInfo.type === "common") {
      switch (columnId) {
        case "date":
          const formattedDate = formatDate(newValue);
          console.log(`Order ID: ${orderId}, Updated Date: ${formattedDate}`);
          break;
        case "customName":
        case "type":
        case "review":
        case "status":
        case "assignees":
        case "room":
        case "note":
          console.log(`Order ID: ${orderId}, Updated ${columnId}: ${newValue}`);
          break;
      }
    } else {
      console.log(
        `Order ID: ${orderId}, Type: ${updateInfo.type}, Value Type: ${updateInfo.valueType}, Name: ${updateInfo.name}, New Value: ${newValue}`
      );
    }

    // Call the updateOrder function
    handleUpdateOrder(orderId, updateInfo, newValue);
    // Here you would typically update your backend with the new value
    // updateBackend(orderId, columnId, newValue, updateInfo);
  };

  const fetchTreatments = async (patientId) => {
    try {
      // setIsLoading(true);
      const ordersResponse = await axios.get(
        `/api/patient-orders/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (ordersResponse.status === 200) {
        if (ordersResponse.data.length === 0) {
          setOrders([]);
          setRowData([]);
          // toastifyToast.success("Treatments fetched successfully");
          // setIsLoading(false);
        } else {
          let filteredOrders = ordersResponse.data.map((order) => {
            return { ...order, orderId: order._id };
          });
          setOrders(filteredOrders);
          toastifyToast.success("Treatments fetched successfully");
          // setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching treatment data:", error);
      toastifyToast.error(`Failed to fetch treatments: ${error.message}`);
      // setIsLoading(false);
    }
  };

  const getCheckedLabs = (userLabs) => {
    return userLabs.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  // const tooltipLabs = getCheckedLabs(patient.userLabs);
  // const tooltipDispensed = getDispensedItemsWithSelectedField(
  // patient.userdispensed
  // );
  // const tooltipProcedures = getRelevantProcedures(patient.userProcedures);

  const getTooltipContent = (labs, dispensed, procedures) => (
    <div>
      {labs.length > 0 && (
        <div className="mb-4">
          <strong>Labs:</strong>
          {labs.map((lab) => (
            <div key={lab._id} className="my-2">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensed.length > 0 && (
        <div className={`mb-4 ${labs.length > 0 ? "mt-4" : ""}`}>
          <strong>Dispensed:</strong>
          {dispensed.map((item) => (
            <div key={item._id} className="my-2">
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {procedures.length > 0 && (
        <div
          className={`mb-4 ${
            labs.length > 0 || dispensed.length > 0 ? "mt-4" : ""
          }`}
        >
          <strong>Procedures:</strong>
          {procedures.map((procedure) => (
            <div key={procedure._id} className="my-2">
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const fetchAssigneeOptions = async () => {
    console.log("Fetching assignee options");
    // TODO: Implement API call to fetch assignee options
    // For now, return a dummy array
    return ["Assignee 1", "Assignee 2", "Assignee 3"];
  };

  const fetchRoomOptions = async () => {
    console.log("Fetching room options");
    // TODO: Implement API call to fetch room options
    // For now, return a dummy array
    return ["Room 1", "Room 2", "Room 3"];
  };

  const handleRefresh = async () => {
    if (selectedPatient) {
      await fetchTreatments(selectedPatient["Customer ID"]);
    }
  };

  const fetchCalendars = async (date) => {
    try {
      const response = await axios.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const calendarIds = response.data.map((item) => item._id); // Get an array of calendarIds

        const requestPromises = response.data.map((item) =>
          axios.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const updatedResults = [];

        // Iterate through results and calendarIds simultaneously
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const calendarId = calendarIds[i]; // Get the corresponding calendarId

          if (result?.data?.message === "No events .") {
            continue; // Skip this result if there are no events
          }

          const events = result.data.events; // Get all events from the result

          for (const event of events) {
            try {
              const orderResponse = await axios.get(
                `/orders-by-event/${event.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );

              if (orderResponse.data && orderResponse.data.length > 0) {
                event.order = true;
              } else {
                event.order = false;
              }

              // Add calendarId to the event
              event.calendarId = calendarId;

              updatedResults.push(event);
            } catch (error) {
              console.error("Error fetching orders:", error);
              event.calendarId = calendarId;
              event.order = false;
              updatedResults.push(event);
            }
          }
        }
        setUpdatedEvents(updatedResults);
      }
    } catch (error) {
      console.error("Error fetching calendars:", error);
    }
  };
  useEffect(() => {
    fetchCalendars(selectedDate.format("YYYY-MM-DD"));
  }, [selectedDate]);
  const [editedValues, setEditedValues] = useState({});
  const handlePreviousDate = () => {
    const previousDate = selectedDate.subtract(1, "day");
    setSelectedDate(previousDate);
    // fetchCalendars(previousDate.format("YYYY-MM-DD")); // Assuming fetchCalendars is defined elsewhere
  };
  const handleNextDate = () => {
    const nextDate = selectedDate.add(1, "day");
    setSelectedDate(nextDate);
    // fetchCalendars(nextDate.format("YYYY-MM-DD")); // Assuming fetchCalendars is defined elsewhere
  };
  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }
  const handleCellChange = (patientId, columnName, newValue, category) => {
    if (category === "userProcedures") {
      const treatment = orders?.find((item) => item._id === treatment._id);
      if (treatment.review === true) {
        toast.warn("Changes can't be made as treatment is finalized");
        return;
      }
    }
    if (columnName != "custom" || columnName != "note") {
      // Update local state first
      setEditedValues((prevValues) => ({
        ...prevValues,
        [`${patientId}-${columnName}`]: newValue,
      }));
      // updateEditedValues({ [`${patientId}-${columnName}`]: newValue });
    }

    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
      };
    }

    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateTreatmentWithEventDataTime = async (event, treatment) => {
    const eventDate = moment(event?.start?.dateTime).format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    const eventTime = moment(event?.start?.dateTime).format("hh:mm A");

    try {
      handleCellChange(treatment?._id, "event", event?.id, "patientInfo");
      handleCellChange(treatment?._id, "date", eventDate, "patientInfo");
      handleCellChange(treatment?._id, "time", eventTime, "patientInfo");
      const responseWatch = await axios.post(
        `/create-watch`,
        {
          eventId: event?.id,
          treatmentId: treatment?._id,
          calendarId: event?.calendarId,
          status: treatment?.status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toast.success("Event assigned to treatment");
    } catch (error) {
      // toast.error(error.message);
      console.error("Error creating treatment:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  useEffect(() => {
    console.log("useEffect Called", showNotes);
    let filteredOrders = orders;

    if (checkboxes.activeDispensed) {
      filteredOrders = filteredOrders.filter((order) =>
        order.userdispensed.some((item) => item.quantity > 0)
      );
    }

    if (checkboxes.activeLabs) {
      filteredOrders = filteredOrders.filter((order) =>
        order.userLabs.some((item) => item.checked)
      );
    }

    if (checkboxes.activeProcedures) {
      filteredOrders = filteredOrders.filter((order) =>
        order.userProcedures.some((item) => item.checked)
      );
    }
    processOrdersData(filteredOrders);
    console.log("Order updated", orders);
  }, [showNotes, showLabs, showProcedures, showDispensed, orders, checkboxes]);

  const processOrdersData = (orders) => {
    if (orders?.length === 0) return;

    const sampleOrder = orders[0];
    let commonColumns = [
      { field: "orderId", headerName: "Order ID", hide: true },
      {
        field: "date",
        headerName: "Date",
        cellRenderer: DateCellRenderer,
        valueFormatter: (params) => formatDate(params.value),
        minWidth: 160,
      },
      {
        field: "customName",
        headerName: "Custom Name",
        cellRenderer: NoteCellRenderer,
        minWidth: 160,
      },
      {
        field: "type",
        headerName: "Type",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: ["Order", "Treatment"] },
        minWidth: 160,
      },
      {
        field: "review",
        headerName: "Final",
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { from: "review" },
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: ["scheduled", "arrived", "completed"] },
        minWidth: 160,
      },
      {
        field: "assignees",
        headerName: "Assignees",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: [] }, // We'll populate this later
        minWidth: 160,
      },
      {
        field: "room",
        headerName: "Room",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: [] }, // We'll populate this later
        minWidth: 160,
      },
      {
        field: "note",
        headerName: "Note",
        cellRenderer: NoteCellRenderer,
        minWidth: 160,
      },
      {
        field: "del/dup",
        headerName: "Del / Dup",
        cellRenderer: DelDupRenderer,
        cellRendererParams: (params) => ({
          treatment: orders?.find((order) => order._id === params.data.orderId),
          orders,
          setOrders,
          selectedDate,
          setUpdatedEvents,
          updatedEvents,
          isPopoverOpen,
          setIsPopoverOpen,
        }),
        minWidth: 120,
      },
      {
        field: "lpd",
        headerName: "LPDV",
        cellRenderer: LPDCellRenderer,
        cellRendererParams: (params) => ({
          getTooltipContent,
          treatment: orders?.find((order) => order._id === params.data.orderId),
          patient: selectedPatient,
          setModalType,
          setShowModal,
          setActiveTreatmentId,
        }),
        minWidth: 160,
      },
    ];

    let procedureColumns = sampleOrder.userProcedures.map((procedure) => {
      const baseColumn = {
        headerName: procedure.procedure_name,
        headerTooltip: `${procedure.procedure_name}`,
        children: [
          {
            field: `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`,
            headerName: "Dosage",
            headerComponent:
              procedure.dosages && procedure.dosages.length > 0
                ? undefined
                : CheckboxHeader,
            headerComponentParams:
              procedure.dosages && procedure.dosages.length > 0
                ? undefined
                : {},
            cellRenderer:
              procedure.dosages && procedure.dosages.length > 0
                ? DropdownCellRenderer
                : CheckboxCellRenderer,
            cellRendererParams: procedure.dosages
              ? { values: procedure.dosages }
              : undefined,
            minWidth: 150,
          },
        ],
      };

      if (procedure.customDosage) {
        baseColumn.children.push({
          field: `procedure_${procedure.procedure_name.replace(
            /\s+/g,
            "_"
          )}_customDosage`,
          headerName: "Custom Dosage",
          cellRenderer: NoteCellRenderer,
          minWidth: 150,
        });
      }

      if (procedure.hasOwnProperty("note")) {
        baseColumn.children.push({
          field: `procedure_${procedure.procedure_name.replace(
            /\s+/g,
            "_"
          )}_note`,
          headerName: "Note",
          cellRenderer: NoteCellRenderer,
          minWidth: 150,
        });
      }

      return baseColumn;
    });

    let dispensedColumns = sampleOrder.userdispensed.map((dispensed) => {
      const baseColumn = {
        headerName: dispensed.name,
        headerTooltip: `${dispensed.name}`,
        children: [
          {
            field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}`,
            headerName: "Dosage",
            cellRenderer: DropdownCellRenderer,
            cellRendererParams: { values: dispensed.dosages },
            minWidth: 150,
          },
          {
            field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}_quantity`,
            headerName: "Quantity",
            cellRenderer: QuantityCellRenderer,
            minWidth: 150,
          },
        ],
      };

      if (dispensed.hasOwnProperty("note")) {
        baseColumn.children.push({
          field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}_note`,
          headerName: "Note",
          cellRenderer: NoteCellRenderer,
          minWidth: 150,
        });
      }

      return baseColumn;
    });
    let labColumns = sampleOrder.userLabs.map((lab) => ({
      field: `lab_${lab.test_name.replace(/\s+/g, "_")}`,
      headerName: lab.test_name,
      headerTooltip: `${lab.test_name}`,
      cellRenderer: CheckboxCellRenderer,
      minWidth: 150,
    }));

    if (!showNotes) {
      commonColumns = commonColumns.filter(
        (column) => column.headerName !== "Note"
      );
      procedureColumns = procedureColumns.map((column) => {
        return {
          ...column,
          children: column.children.filter(
            (child) => child.headerName !== "Note"
          ),
        };
      });
      dispensedColumns = dispensedColumns.map((column) => {
        return {
          ...column,
          children: column.children.filter(
            (child) => child.headerName !== "Note"
          ),
        };
      });
    }

    let filteredColumns = {
      common: commonColumns,
    };
    if (showProcedures) {
      // setAllColumnDefs({
      //   common: commonColumns,
      //   dispensed: dispensedColumns,
      //   labs: labColumns,
      // });
      filteredColumns.procedures = procedureColumns;
    }
    if (showDispensed) {
      // setAllColumnDefs({
      //   common: commonColumns,
      //   procedures: procedureColumns,
      //   labs: labColumns,
      // });
      filteredColumns.dispensed = dispensedColumns;
    }
    if (showLabs) {
      // setAllColumnDefs({
      //   common: commonColumns,
      //   labs: labColumns,
      // });
      filteredColumns.labs = labColumns;
    }
    setAllColumnDefs(filteredColumns);

    setColumnDefs([
      ...commonColumns,
      ...procedureColumns,
      ...dispensedColumns,
      ...labColumns,
    ]);

    const newRowData = orders?.map((order) => {
      const rowData = {
        orderId: order._id,
        date: order.date,
        customName: order.custom || "",
        type: order.type || "Order",
        review: order.review || false,
        status: order.status || "",
        assignees: order.assignees || [],
        room: order.room || "",
        note: order.note || "",
      };

      order.userLabs.forEach((lab) => {
        rowData[`lab_${lab.test_name.replace(/\s+/g, "_")}`] = lab.checked;
      });

      order.userdispensed.forEach((dispensed) => {
        rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}`] =
          dispensed.selectedField || "";
        rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}_quantity`] =
          dispensed.quantity || 0;
        if (dispensed.hasOwnProperty("note")) {
          rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}_note`] =
            dispensed.note || "";
        }
      });

      order.userProcedures.forEach((procedure) => {
        if (procedure.dosages && procedure.dosages.length > 0) {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`
          ] = procedure.selectedField || "";
        } else {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`
          ] = procedure.checked;
        }
        if (procedure.customDosage) {
          rowData[
            `procedure_${procedure.procedure_name.replace(
              /\s+/g,
              "_"
            )}_customDosage`
          ] = procedure.customDosage || "";
        }
        if (procedure.hasOwnProperty("note")) {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}_note`
          ] = procedure.note || "";
        }
      });

      return rowData;
    });

    setRowData(newRowData);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  function addNewOrderTemplate(obj) {
    if (template?._id) {
      const url = `/addTreatmentWithTemplate/${selectedPatient["Customer ID"]}/${obj.type}/${template?._id}`;
      axios
        .post(
          url,
          {}, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          handleSelectPatient(selectedPatient._id);
          // patientDataTableRef.current.handleHideEmptyColumns();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const url = `/patient/order/new/${selectedPatient["Customer ID"]}/${obj.type}`;
      axios
        .post(
          url,
          { date: obj.date }, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          handleSelectPatient(selectedPatient._id);
          // patientDataTableRef.current.handleHideEmptyColumns();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function addNewOrder(type) {
    const url = `/patient/order/new/${selectedPatient["Customer ID"]}/${type.type}`;
    axios
      .post(
        url,
        {}, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        handleSelectPatient(selectedPatient._id);
        // patientDataTableRef.current.handleHideEmptyColumns();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const sortCheckedItems = (items) => {
    return items.slice().sort((a, b) => {
      if (a.checked === true && b.checked === false) return -1;
      if (a.checked === false && b.checked === true) return 1;
      const originalIndexA = supplements.findIndex(
        (item) => item.name === a.name
      );
      const originalIndexB = supplements.findIndex(
        (item) => item.name === b.name
      );
      return originalIndexA - originalIndexB;
    });
  };
  useEffect(() => {
    if (allColumnDefs.common) {
      let filteredColumnDefs;
      switch (filter) {
        case "labs":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.labs || []),
          ];
          break;
        case "dispensed":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.dispensed || []),
          ];
          break;
        case "procedures":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.procedures || []),
          ];
          break;
        default:
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.procedures || []),
            ...(allColumnDefs.dispensed || []),
            ...(allColumnDefs.labs || []),
          ];
      }
      setColumnDefs(filteredColumnDefs);
    }
  }, [filter, allColumnDefs]);

  useEffect(() => {
    if (selectedPatient) {
      const fetchOptions = async () => {
        const assigneeOptions = await fetchAssigneeOptions();
        const roomOptions = await fetchRoomOptions();

        setAllColumnDefs((prevState) => ({
          ...prevState,
          common: prevState.common?.map((col) => {
            if (col.field === "assignees") {
              return {
                ...col,
                cellRendererParams: {
                  ...col.cellRendererParams,
                  values: assigneeOptions,
                },
              };
            }
            if (col.field === "room") {
              return {
                ...col,
                cellRenderer: DropdownCellRenderer,
                cellRendererParams: { values: roomOptions },
              };
            }
            return col;
          }),
        }));
      };

      fetchOptions();
    }
    console.log("******* selected Patient", selectedPatient);
  }, [selectedPatient]);

  useEffect(() => {
    const initialSelectedSupplements = {};
    function updateArrayWithIds(array, ids) {
      return array.map((obj) => {
        if (ids[obj._id]) {
          return {
            ...obj,
            frequencyOptions: ["1 dosage twice a day", ...obj.frequency],
            ...ids[obj._id],
          };
        } else {
          return {
            ...obj,
            checked: false,
            frequencyOptions: ["1 dosage twice a day", ...obj.frequency],
          };
        }
      });
    }
    const updatedArray = updateArrayWithIds(supplements, selectedSupplements);
    const sortedItems = sortCheckedItems([...updatedArray]);
    setUpdatedItems(sortedItems);
  }, [selectedSupplements, supplements]);

  useEffect(() => {
    const initialSelectedSupplements = {};
    setHistoricalSupplements(selectedPatientSH?.HistoricalSupplements);
    selectedPatientSH?.RecommendedSupplements.forEach((recSupp) => {
      const matchingSupplement = supplements.find(
        (sup) => sup.supplement_name === recSupp.supplementName
      );
      if (matchingSupplement) {
        initialSelectedSupplements[matchingSupplement._id] = {
          checked: true,
          frequency: recSupp.frequency,
          inHistory: false,
        };
      }
    });

    selectedPatientSH?.HistoricalSupplements.forEach((histSupp) => {
      const matchingSupplement = supplements.find(
        (sup) => sup.supplement_name === histSupp.supplementName
      );
      if (matchingSupplement) {
        initialSelectedSupplements[matchingSupplement._id] = {
          checked: false,
          frequency: histSupp.frequency,
          inHistory: true,
        };
      }
    });

    setSelectedSupplements(initialSelectedSupplements);
  }, [selectedPatientSH, supplements]);

  const handleVitalsModalOpen = () => setOpenVitalsModal(true);
  const handleVitalsModalClose = () => setOpenVitalsModal(false);

  const filteredItems =
    updatedItems &&
    updatedItems.filter((item) =>
      searchTerm && typeof searchTerm === "string"
        ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
        : true
    );
  const handleOpenSupplements = () => {
    if (selectedPatientId) {
      setOpenSupplements(true);
    } else {
      toastifyToast.info("Please select Patient");
    }
  };
  const handleCloseSupplements = () => {
    setSearchTerm("");
    setCustomFrequency({});
    setIsCustomFrequencySelected({});
    setOpenSupplements(false);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "letter",
    });

    pdfDocRef.current = doc;

    const renderHeader = (doc) => {
      doc.setFontSize(10);

      doc.text("PATIENT", 10, 10);
      doc.setFont(undefined, "bold");
      doc.setFontSize(12);
      doc.text(`${selectedPatientSH["Full Name"]}`, 10, 15);
      doc.setFontSize(10);
      doc.setFont(undefined, "normal");
      doc.text(
        `DOB: ${moment(selectedPatientSH["DOB"]).format("MM/DD/YYYY")}`,
        10,
        20
      );
      doc.text(
        `AGE: ${moment().diff(moment(selectedPatientSH["DOB"]), "years")} yrs`,
        10,
        25
      );
      doc.text(`PRN: ${selectedPatientSH["Customer ID"]}`, 10, 30);

      doc.text("FACILITY", 75, 10);
      doc.setFont(undefined, "bold");
      doc.text("Sunridge Medical", 75, 15);
      doc.setFontSize(10);
      doc.setFont(undefined, "normal");
      doc.text("T (480) 659-9135", 75, 20);
      doc.text("F (480) 659-9197", 75, 25);
      doc.text("14200 N Northsight Blvd 160", 75, 30);
      doc.text("Scottsdale, AZ 85260", 75, 35);
      doc.text(`Print Date: ${moment().format("MM/DD/YYYY")}`, 135, 10);
    };

    renderHeader(doc);

    let startY = 40; // Initial startY position for the tables

    const formatSupplements = (supplements, title) => {
      const tableColumn = ["Supplement Name", "Frequency"];
      const tableRows = [];

      supplements.forEach(([supplementId, supplementData]) => {
        const matchingSupplement = supplements.find(
          (sup) => sup._id === supplementId
        );
        if (matchingSupplement) {
          tableRows.push([
            matchingSupplement.supplement_name,
            supplementData.frequency,
          ]);
        }
      });

      return { tableColumn, tableRows, title };
    };

    if (printOptions.recommended) {
      const recommendedSupplements = Object.entries(selectedSupplements).filter(
        ([, value]) => value.checked && !value.inHistory
      );
      const { tableColumn, tableRows, title } = formatSupplements(
        recommendedSupplements,
        "Recommended Supplements"
      );

      doc.text(title, 15, startY);
      startY += 5; // Increase startY position for the next table
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY,
        theme: "grid",
        headStyles: { fillColor: [220, 220, 220] },
      });
      startY = doc.lastAutoTable.finalY + 5; // Update startY for the next table
    }

    if (printOptions.historical) {
      const historicalSupplements = Object.entries(selectedSupplements).filter(
        ([, value]) => value.inHistory
      );
      const { tableColumn, tableRows, title } = formatSupplements(
        historicalSupplements,
        "Historical Supplements"
      );

      doc.text(title, 15, startY);
      startY += 5; // Increase startY position for the next table
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY,
        theme: "grid",
        headStyles: { fillColor: [220, 220, 220] },
      });
    }
    const pdfBlob = doc.output("blob");

    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
    // doc.save("Supplements_Data.pdf");
    setOpenPreview(true);
  };

  const handleCheckboxChangeNew = (supId) => {
    setSelectedSupplements((prev) => ({
      ...prev,
      [supId]: prev[supId]
        ? { ...prev[supId], checked: !prev[supId].checked }
        : { checked: true, frequency: "1 dosage twice a day" },
    }));
  };

  const handleDownloadPDF = () => {
    if (pdfDocRef.current) {
      pdfDocRef.current.save("Supplements_Data.pdf");
    }
  };

  const handleFrequencyChange = (supId, frequency) => {
    setSelectedSupplements((prev) => ({
      ...prev,
      [supId]: { ...prev[supId], frequency },
    }));
  };

  const addFrequency = async (supplement, value) => {
    const matchedSupplement = supplements.find(
      (supp) => supp.supplement_name === supplement.supplement_name
    );

    try {
      const response = await axios.post(
        `/supplements/${matchedSupplement._id}/add-frequency`,
        {
          newFrequency: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast("Frequency added successfully", { type: "success" });
    } catch (error) {
      toastifyToast(
        `Error adding frequency: ${
          error.response?.data?.message || error.message
        }`,
        { type: "error" }
      );
    }
  };

  const removeFrequency = async (supplement, value) => {
    const matchedSupplement = supplements.find(
      (supp) => supp.supplement_name === supplement.supplement_name
    );

    try {
      const response = await axios.post(
        `/supplements/${matchedSupplement._id}/remove-frequency`,
        {
          frequencyToRemove: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast("Frequency removed successfully", { type: "success" });
    } catch (error) {
      toastifyToast(
        `Error adding frequency: ${
          error.response?.data?.message || error.message
        }`,
        { type: "error" }
      );
    }
  };

  const handleChangeSwitch = (index, checked) => {
    const updatedElements = [...updatedItems];
    updatedElements[index].checked = checked;
    setUpdatedItems(updatedElements);
    const supplement = updatedElements[index];
    if (
      checked &&
      supplement.frequency &&
      customFrequency[supplement._id].trim() !== ""
    ) {
      addFrequency(supplement, customFrequency[supplement._id]);
    } else if (
      !checked &&
      supplement.frequency &&
      customFrequency[supplement._id].trim() !== ""
    ) {
      removeFrequency(supplement, customFrequency[supplement._id]);
    }
  };

  const handleRemoveFromHistory = (supplementId, supplementName) => {
    const updatedSelectedSupplements = { ...selectedSupplements };
    setLoadingSupplementId(supplementId);

    const supplement = updatedSelectedSupplements[supplementId];
    updatedSelectedSupplements[supplementId] = {
      ...updatedSelectedSupplements[supplementId],
      inHistory: false,
    };

    setSelectedSupplements(updatedSelectedSupplements);
    const matchedSupplements = [];
    selectedPatientSH?.HistoricalSupplements.forEach((histSupp) => {
      const matchingSupplement = supplements.find(
        (sup) => sup.supplement_name === histSupp.supplementName
      );
      if (matchingSupplement) {
        matchedSupplements.push(matchingSupplement);
      }
    });

    const matchedSupplement = matchedSupplements.find(
      (supp) => supp.supplement_name === supplementName
    );
    setMatchedSupplement(matchedSupplement);
    const newHistoricalSupplements = [
      // ...historicalSupplements,
      {
        supplementName: supplements.find((sup) => sup._id === supplementId)
          .supplement_name,
        frequency: supplement.frequency,
      },
    ];

    const profileData = {
      patientId: selectedPatientId,
      supplementsToMove: newHistoricalSupplements,
    };

    axios
      .post("/MoveHistoricalToRecommended", profileData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        axios
          .get(
            `/patient-profiles/${selectedPatient["Customer ID"]}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
          .then((response) => {
            setSelectedPatientSH(response.data);
            showToast("success", "Removed from history successfully!");
            setLoadingSupplementId(null);
          })
          .catch((error) => {
            showToast("error", "Failed to remove from history!");
            console.error(error);
            setLoadingSupplementId(null);
          });
      })
      .catch((error) => {
        showToast("error", "Failed to remove from history!");
        console.error(error);
        setLoadingSupplementId(null);
      });
  };

  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };

  const handleMoveToHistory = (supplementId) => {
    const updatedSelectedSupplements = { ...selectedSupplements };
    const supplement = updatedSelectedSupplements[supplementId];

    const newHistoricalSupplements = [
      ...historicalSupplements,
      {
        supplementName: supplements.find((sup) => sup._id === supplementId)
          .supplement_name,
        frequency: supplement.frequency,
      },
    ];

    updatedSelectedSupplements[supplementId] = {
      ...supplement,
      checked: false,
      inHistory: true,
    };

    setSelectedSupplements(updatedSelectedSupplements);
    setHistoricalSupplements(newHistoricalSupplements);

    const profileData = {
      patientId: selectedPatientId,
      HistoricalSupplements: newHistoricalSupplements,
    };

    axios
      .post(
        "/Historical-RecommendedSupplements",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        showToast("success", "Moved to history successfully!");
      })
      .catch((error) => {
        showToast("error", "Failed to move to history!");
        console.error(error);
      });
  };

  const handleSubmitSupplements = (e) => {
    const selectedData = Object.entries(selectedSupplements)
      .filter(([, value]) => value.checked)
      .map(([key, value]) => {
        const supplement = supplements.find((sup) => sup._id === key);
        return {
          supplementName: supplement.supplement_name,
          frequency: value.frequency,
        };
      });

    const profileData = {
      patientId: selectedPatientId,
      RecommendedSupplements: selectedData,
    };

    axios
      .post(
        "/Historical-RecommendedSupplements",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        message.success("Data submitted successfully!");
        handleCloseSupplements();
      })
      .catch((error) => {
        message.error("Failed to submit data!");
        console.error(error);
      });
  };
  const fetchPatientProfile = async (customerId) => {
    try {
      const response = await axios.get(
        `/patient-profiles/${customerId}`, // Replace with your actual base URL and endpoint
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      setSelectedPatientSH(response.data);
      // Handle the successful response, e.g., setting state or returning the data
      return response.data;
    } catch (error) {
      console.error("Error fetching patient profile:", error);
      // Handle the error appropriately in your application, e.g., showing an error message
      throw error;
    }
  };

  const handleNotesToggle = () => {
    setShowNotes(!showNotes);
  };
  const handleLabsToggle = () => {
    setShowLabs(!showLabs);
  };
  const handleProceduresToggle = () => {
    setShowProcedures(!showProcedures);
  };
  const handleDispensedToggle = () => {
    setShowDispensed(!showDispensed);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <div style={{ padding: 30 }}>
      <ToastContainer />
      <h1 className="text-2xl md:text-xl font-bold text-gray-800 tracking-wide">
        Treatment View (Under Development)
      </h1>
      <div className="my-4 flex items-center justify-between">
        <Box
          component={Paper}
          elevation={1}
          borderRadius={2}
          padding={3}
          marginY={2}
        >
          <div>
            <PatientSearch
              patients={patients}
              onSearch={handleSearch}
              onSelectPatient={handleSelectPatient}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
              selectedPatientName={
                selectedPatient && selectedPatient["Full Name"]
              }
            />
            {selectedPatient && (
              <div className="ml-2 inline-block">
                <UpdatePatient patient={selectedPatient} />
              </div>
            )}
          </div>
          {selectedPatient && !isLoading && (
            <>
              <div className="flex items-center gap-4 mt-4">
                <Button
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={!selectedPatient}
                  className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  icon={<ReloadOutlined />}
                >
                  Refresh Treatments
                </Button>
                <Button
                  onClick={() => createNewOrder("Order")}
                  disabled={!selectedPatient}
                  className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  icon={<PlusOutlined />}
                >
                  New Order
                </Button>

                <TreatmentTemplates
                  setTemplate={setTemplate}
                  addNewOrderTemplate={addNewOrderTemplate}
                  addNewOrder={addNewOrder}
                />
              </div>
            </>
          )}
        </Box>
        {selectedPatient && !isLoading && (
          <Box
            component={Paper}
            elevation={1}
            borderRadius={2}
            paddingY={2}
            paddingX={3}
            marginY={2}
          >
            <div className="flex items-center gap-4">
              <Button
                onClick={() => fetchVitalData(selectedPatient["Customer ID"])}
                className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
              >
                All Vitals
              </Button>
              <Button
                onClick={() => {
                  fetchSupplementNames();
                  fetchPatientProfile(selectedPatient["Customer ID"]);
                  handleOpenSupplements();
                }}
                className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
              >
                Supplements
              </Button>
            </div>
          </Box>
        )}
      </div>

      {rowData.length > 0 && !isLoading && (
        <div>
          <Box
            component={Paper}
            elevation={1}
            borderRadius={2}
            padding={3}
            marginBottom={4}
            width={"fit-content"}
          >
            <h2 className="text-2xl md:text-xl font-bold text-gray-800 tracking-wide">
              Table Filters
            </h2>
            <ColumnFilters
              filter={filter}
              onFilterChange={handleFilterChange}
            />
            <div className="flex items-center gap-4 my-4">
              <AntdTooltip
                title={`Click to ${showNotes ? "hide" : "show"} notes`}
              >
                <Button
                  className={
                    showNotes
                      ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                      : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
                  }
                  onClick={handleNotesToggle}
                  icon={showNotes ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                >
                  {showNotes ? "Hide Notes" : "Show Notes"}
                </Button>
              </AntdTooltip>
              <AntdTooltip
                title={`Click to ${showLabs ? "hide" : "show"} labs`}
              >
                <Button
                  className={
                    showLabs
                      ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                      : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
                  }
                  onClick={handleLabsToggle}
                  icon={showLabs ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                >
                  {showLabs ? "Hide Labs" : "Show Labs"}
                </Button>
              </AntdTooltip>
              <AntdTooltip
                title={`Click to ${
                  showProcedures ? "hide" : "show"
                } procedures`}
              >
                <Button
                  className={
                    showProcedures
                      ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                      : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
                  }
                  onClick={handleProceduresToggle}
                  icon={
                    showProcedures ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                >
                  {showProcedures ? "Hide Procedures" : "Show Procedures"}
                </Button>
              </AntdTooltip>
              <AntdTooltip
                title={`Click to ${
                  showDispensed ? "hide" : "show"
                } dispensed items`}
              >
                <Button
                  className={
                    showDispensed
                      ? "py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                      : "py-2 px-4 text-sm bg-gray-500 hover:!bg-gray-600 hover:!text-white text-white rounded-md focus:outline-none"
                  }
                  onClick={handleDispensedToggle}
                  icon={
                    showDispensed ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                >
                  {showDispensed ? "Hide Dispensed" : "Show Dispensed"}
                </Button>
              </AntdTooltip>
            </div>
            <div className="flex items-center gap-4 my-4">
              <AntdCheckbox
                name="activeLabs"
                checked={checkboxes.activeLabs}
                onChange={handleCheckboxChange}
              >
                Active Labs
              </AntdCheckbox>
              <AntdCheckbox
                name="activeProcedures"
                checked={checkboxes.activeProcedures}
                onChange={handleCheckboxChange}
              >
                Active Procedures
              </AntdCheckbox>
              <AntdCheckbox
                name="activeDispensed"
                checked={checkboxes.activeDispensed}
                onChange={handleCheckboxChange}
              >
                Active Dispensed
              </AntdCheckbox>
            </div>
          </Box>

          {rowData.length > 0 && (
            <TreatmentTable
              rowData={rowData}
              columnDefs={columnDefs}
              onCellValueChanged={handleCellValueChanged}
            />
          )}
        </div>
      )}

      {selectedPatient && !isLoading && rowData.length === 0 && (
        <div className="my-5 py-52 px-10 rounded-xl shadow-md flex items-center justify-center gap-3">
          <WarningFilled style={{ color: yellow[700], fontSize: "36px" }} />
          <Typography
            variant="h6"
            className="text-4xl font-bold"
            sx={{ fontWeight: 400 }}
          >
            No treatments found for the selected patient
          </Typography>
        </div>
      )}
      {!selectedPatient && !isLoading && (
        <>
          <div className=" my-5 py-52 px-10 rounded-xl shadow-md flex items-center justify-center gap-3">
            <Warning sx={{ color: yellow[700], fontSize: "36px" }} />
            <Typography
              variant="h6"
              className="text-4xl font-bold"
              sx={{ fontWeight: 400 }}
            >
              Please select a patient to see the treatments
            </Typography>
          </div>
        </>
      )}

      {isLoading && (
        <>
          <div className=" my-5  p-10 rounded-xl shadow-md flex flex-col items-center justify-center gap-3">
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={260}
              sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
            />
          </div>
        </>
      )}
      {showModal && (
        <LPDPopup
          type={modalType}
          patientName={selectedPatient["Full Name"]}
          allTreatments={orders}
          setAllTreatments={setOrders}
          treatment={orders?.find((order) => order._id === activeTreatmentId)}
          showModal={showModal}
          setShowModal={setShowModal}
          setModalType={setModalType}
        />
      )}
      {vitalData && (
        <Modal open={openVitalsModal} onClose={handleVitalsModalClose}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Latest Vital Information
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="vital data table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Date</TableCell>
                    <TableCell align="right">Time</TableCell>
                    <TableCell align="right">Blood Pressure</TableCell>
                    <TableCell align="right">Heart Rate</TableCell>
                    <TableCell align="right">SPO2</TableCell>
                    <TableCell align="right">Temperature</TableCell>
                    <TableCell align="right">Weight</TableCell>
                    {/* Add other vital headers here */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vitalData.map((order) =>
                    order.vitals && order.vitals.length > 0 ? (
                      order.vitals.map((vital, index) => (
                        <TableRow key={`${order._id}-${index}`}>
                          <TableCell component="th" scope="row">
                            {index === 0
                              ? new Date(order.date).toLocaleDateString()
                              : ""}
                          </TableCell>
                          <TableCell align="right">
                            {new Date(vital.time).toLocaleTimeString()}
                          </TableCell>
                          <TableCell align="right">{vital.BP}</TableCell>
                          <TableCell align="right">{vital.HR}</TableCell>
                          <TableCell align="right">{vital.SPO2}</TableCell>
                          <TableCell align="right">{vital.T}</TableCell>
                          <TableCell align="right">{vital.W}</TableCell>
                          {/* Add other vital cells here */}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={order._id}>
                        <TableCell component="th" scope="row" colSpan={7}>
                          No vital data available for this order.
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      )}
      <Dialog
        open={openSupplements}
        onClose={handleCloseSupplements}
        maxWidth="lg"
        fullWidth
      >
        <Box p={4} display="flex" flexDirection="column" height="80vh">
          <div className="flex justify-between">
            {" "}
            <DialogTitle>Add Supplements to Patient Treatment Plan</DialogTitle>
            <div className="flex justify-center items-center">
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.recommended}
                      onChange={() =>
                        setPrintOptions((prev) => ({
                          ...prev,
                          recommended: !prev.recommended,
                        }))
                      }
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.historical}
                      onChange={() =>
                        setPrintOptions((prev) => ({
                          ...prev,
                          historical: !prev.historical,
                        }))
                      }
                    />
                  }
                  label="Historical"
                />
                <Tooltip
                  title={
                    !printOptions.recommended && !printOptions.historical
                      ? "Check Active or Historical option to Print"
                      : "Print"
                  }
                >
                  <span>
                    <IconButton
                      disabled={
                        !printOptions.recommended && !printOptions.historical
                      }
                      onClick={() => handleGeneratePDF()}
                    >
                      <Print />
                    </IconButton>
                  </span>
                </Tooltip>
                <div>
                  <Dialog
                    open={openPreview}
                    onClose={() => {
                      setOpenPreview(false);
                    }}
                    maxWidth="lg"
                    fullWidth
                    PaperProps={{
                      style: { height: "100%", padding: "10px 20px" },
                    }}
                  >
                    <DialogTitle>
                      PDF Preview
                      <button
                        className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none"
                        onClick={handleDownloadPDF}
                        style={{ float: "right" }}
                      >
                        Download PDF
                      </button>
                    </DialogTitle>
                    <DialogContent
                      dividers
                      style={{ height: "calc(100% - 64px)" }}
                    >
                      {pdfUrl && (
                        <iframe
                          src={pdfUrl}
                          width="100%"
                          height="100%"
                          title="PDF Preview"
                          style={{ border: "none" }}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>

          <DialogContent
            style={{
              flexGrow: 1,
              overflowY: "scroll",
              padding: "1%",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
            />
            <Grid container spacing={2}>
              {filteredItems.map((sup, index) => (
                <Grid item xs={12} key={index}>
                  <div className="flex items-center">
                    <Checkbox
                      checked={selectedSupplements[sup._id]?.checked || false}
                      disabled={
                        sup?.inHistory || loadingSupplementId === sup._id
                      }
                      onChange={() => handleCheckboxChangeNew(sup._id)}
                    />

                    {sup.supplement_name}
                    {selectedSupplements[sup._id]?.checked &&
                      sup.frequencyOptions?.length > 0 && (
                        <>
                          <FormControl
                            style={{
                              marginLeft: "10px",
                              minWidth: "200px",
                            }}
                          >
                            <MuiSelect
                              value={
                                isCustomFrequencySelected[sup._id]
                                  ? "Custom"
                                  : selectedSupplements[sup._id]?.frequency ||
                                    ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "custom") {
                                  setIsCustomFrequencySelected((prev) => ({
                                    ...prev,
                                    [sup._id]: true,
                                  }));
                                  setCustomFrequency((prev) => ({
                                    ...prev,
                                    [sup._id]: "",
                                  }));
                                } else {
                                  setIsCustomFrequencySelected((prev) => ({
                                    ...prev,
                                    [sup._id]: false,
                                  }));
                                  handleFrequencyChange(sup._id, value);
                                }
                              }}
                              displayEmpty
                              renderValue={(selected) =>
                                selected ? (
                                  selected
                                ) : (
                                  <em>
                                    Select Recommended Supplement Frequency
                                  </em>
                                )
                              }
                              style={{
                                padding: "8px 12px",
                                height: "40px",
                              }}
                            >
                              {sup?.frequencyOptions?.length > 0 &&
                                sup.frequencyOptions.map((freq, freqIndex) => (
                                  <MenuItem key={freqIndex} value={freq}>
                                    {freq}
                                  </MenuItem>
                                ))}
                              <MenuItem value="custom">Custom</MenuItem>
                            </MuiSelect>
                          </FormControl>
                          {isCustomFrequencySelected[sup._id] && (
                            <>
                              <TextField
                                value={customFrequency[sup._id]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setCustomFrequency((prev) => ({
                                    ...prev,
                                    [sup._id]: value,
                                  }));
                                  handleFrequencyChange(sup._id, value);
                                }}
                                onBlur={(event) => {
                                  if (sup.checked) {
                                    addFrequency(sup, event.target.value);
                                  }
                                }}
                                placeholder="Enter custom frequency"
                                style={{
                                  marginLeft: "10px",
                                  width: "200px",
                                }}
                                InputProps={{
                                  style: {
                                    height: "40px",
                                  },
                                }}
                                inputProps={{
                                  style: {
                                    padding: "8px 12px",
                                  },
                                }}
                              />
                              <Switch
                                checked={sup.checked}
                                className="bg-gray-300 ml-4 min-w-[100px]"
                                onChange={(checked) =>
                                  handleChangeSwitch(index, checked)
                                }
                                checkedChildren="Permanent"
                                unCheckedChildren="Temporary"
                              />
                            </>
                          )}

                          {selectedSupplements[sup._id]?.frequency &&
                            !selectedSupplements[sup._id]?.inHistory && (
                              <IconButton
                                onClick={() => handleMoveToHistory(sup._id)}
                                color="secondary"
                                title="Move to History"
                              >
                                <HistoryOutlined />
                                <Add
                                  style={{
                                    position: "absolute",
                                    top: 4,
                                    right: 0,
                                    fontSize: "0.5em",
                                    color: "green",
                                  }}
                                />
                              </IconButton>
                            )}
                        </>
                      )}
                    {selectedSupplements[sup._id]?.inHistory && (
                      <IconButton
                        onClick={() =>
                          handleRemoveFromHistory(sup._id, sup.supplement_name)
                        }
                        color="primary"
                        title="Remove from History"
                      >
                        <History />
                        <Remove
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 0,
                            fontSize: "0.5em",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    )}
                    {loadingSupplementId === sup._id && (
                      <Box
                        sx={{
                          display: "flex",
                          width: "24px",
                          height: "24px",
                          ml: 5,
                        }}
                      >
                        <CircularProgress size={24} />
                      </Box>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <div className="flex justify-between w-full">
              <div className="flex justify-between items-center">
                <div className="text-red-500">Note:</div>
                <div className="ml-[5px]">
                  <IconButton color="secondary" title="Move to History">
                    <HistoryOutlined />
                    <Add
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 0,
                        fontSize: "0.5em",
                        color: "green",
                      }}
                    />
                  </IconButton>
                  Add to History
                </div>
                <div className="ml-[5px]">
                  <IconButton color="primary" title="Remove from History">
                    <HistoryOutlined />
                    <RemoveCircleOutline
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 0,
                        fontSize: "0.5em",
                        color: "red",
                      }}
                    />
                  </IconButton>
                  Remove from History
                </div>
              </div>
              <div>
                <Button
                  onClick={handleCloseSupplements}
                  sx={{
                    backgroundColor: "#3b82f6",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#3498db",
                    },
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => handleSubmitSupplements()}
                  sx={{
                    backgroundColor: "#3b82f6",
                    color: "white",
                    marginLeft: "10px",
                    "&:hover": {
                      backgroundColor: "#3498db",
                    },
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </DialogActions>
        </Box>
        {toast && (
          <CustomToast
            message={toast.message}
            variant={toast.variant}
            customStyles={{ bottom: "80px" }}
          />
        )}
      </Dialog>

      <Dialog
        open={isPopoverOpen}
        onClose={() => {
          setIsPopoverOpen(false);
          setUpdatedEvents([]);
        }}
        maxWidth="md"
        fullWidth
      >
        <Box p={1}>
          <DialogTitle>
            <div className="flex justify-between">
              <p>Events</p>
              <div>
                <IconButton onClick={handlePreviousDate}>
                  <ChevronLeft />
                </IconButton>
                <DatePicker
                  selected={selectedDate}
                  value={selectedDate}
                  format="YYYY/MM/DD"
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                />
                <IconButton onClick={handleNextDate}>
                  <ChevronRight />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {" "}
            <div className="overflow-x-auto">
              {updatedEvents.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Start Time
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Link
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {updatedEvents
                      .filter((event) => !event.order)
                      .map((event, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            {event.summary}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {new Date(event.start.dateTime).toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <a
                              href={event.htmlLink}
                              className="text-blue-500 hover:underline"
                            >
                              Link
                            </a>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Autocomplete
                              id={`treatment-autocomplete-${index}`}
                              options={orders?.filter(
                                (option) =>
                                  option.event === "unassigned" || !option.event
                              )}
                              style={{ width: 200 }}
                              getOptionLabel={(option) => {
                                const date = new Date(option["date"]);
                                return date.toISOString().split("T")[0]; // Extracting only the date part
                              }}
                              // value={selectedPatients[index] || null}
                              onChange={(_, newValue) => {
                                updateTreatmentWithEventDataTime(
                                  event,
                                  newValue
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Treatment"
                                  variant="outlined"
                                />
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option["date"] === value["date"]
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p>No events found for selected date </p>
              )}
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default TreatmentView;
