import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField as MuiTextField,
  Select,
  TableCell,
  TableRow,
  Button as MuiButton,
} from "@mui/material";
import {
  Checkbox as AntCheckbox,
  Select as AntdSelect,
  Button,
  Form,
  Space,
  Tooltip,
} from "antd";
import axios from "axios"; // Import axios at the top of your file
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import NoteIcon from "@mui/icons-material/Note";
import { styled } from "@mui/material/styles";
import { DatePicker, Popover } from "antd";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

function formatDate(date) {
  const [year, month, day] = date.split("-");
  const day2 = day.split("T")[0];

  return `${year}-${month}-${day2}`;
}

function calculateWidth(text) {
  const maxWidth = 300; // maximum width you want to allow
  const charWidth = 10; // average width per character, this is a rough estimation
  const calculatedWidth = Math.min(text.length * charWidth, maxWidth);
  return calculatedWidth + "px";
}

const TextField = styled(MuiTextField)({
  "& .MuiInputBase-root": {
    height: "40px",
    textAlign: "center", // Ensure text is centered
  },
});

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export default function MaterialTableRow(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [updatedEvents, setUpdatedEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD")
  );

  const [allChecked, setAllChecked] = useState(true);

  const [form] = Form.useForm();
  useEffect(() => {
    fetchCalendars(selectedDate.format("YYYY-MM-DD"));
  }, [selectedDate]);
  const onFinish = (values) => {
    // Handle form submission here

    handleDuplicateRow(patient["_id"], values.date, values);
  };

  useEffect(() => {
    fetchCalendars(selectedDate.format("YYYY-MM-DD"));
  }, []);

  const handleCheckboxChange = (checkedFieldName) => {
    const values = form.getFieldsValue(); // Get the current form values
    const otherFields = ["labs", "procedure", "dispensed"]; // Names of other checkboxes excluding "All"

    // If any other checkbox is checked, uncheck the "All" checkbox
    if (otherFields.some((field) => values[field])) {
      form.setFieldsValue({ all: false });
      setAllChecked(false);
    } else {
      form.setFieldsValue({ all: true });
      setAllChecked(true);
    }
  };

  const handleNextDate = () => {
    const nextDate = selectedDate.add(1, "day");
    setSelectedDate(nextDate);
    // fetchCalendars(nextDate.format("YYYY-MM-DD")); // Assuming fetchCalendars is defined elsewhere
  };

  const handlePreviousDate = () => {
    const previousDate = selectedDate.subtract(1, "day");
    setSelectedDate(previousDate);
    // fetchCalendars(previousDate.format("YYYY-MM-DD")); // Assuming fetchCalendars is defined elsewhere
  };

  const handleReviewChange = async (orderId, isReviewed) => {
    try {
      const response=await axios.put(
        `/patient/order/update-review/${orderId}`,
        { review: isReviewed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if(response.status===200 || response.statusText==="OK"){
        toast.success("Review status updated successfully");
        props?.setTreatments((prevState) => {
          return prevState.map(item => {
            if (item._id === orderId) {
              return { ...item, review: response.data.review };
            }
            return item;
          });
        });
    }
    } catch (error) {
      toast.error("Error updating review status");
      console.error("Error:", error);
    }
  };

  const fetchCalendars = async (date) => {
    try {
      const response = await axios.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const calendarIds = response.data.map((item) => item._id); // Get an array of calendarIds

        const requestPromises = response.data.map((item) =>
          axios.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const updatedResults = [];

        // Iterate through results and calendarIds simultaneously
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const calendarId = calendarIds[i]; // Get the corresponding calendarId

          if (result?.data?.message === "No events .") {
            continue; // Skip this result if there are no events
          }

          const events = result.data.events; // Get all events from the result

          for (const event of events) {
            try {
              const orderResponse = await axios.get(
                `/orders-by-event/${event.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );

              if (orderResponse.data && orderResponse.data.length > 0) {
                event.order = true;
              } else {
                event.order = false;
              }

              // Add calendarId to the event
              event.calendarId = calendarId;

              updatedResults.push(event);
            } catch (error) {
              console.error("Error fetching orders:", error);
              event.calendarId = calendarId;
              event.order = false;
              updatedResults.push(event);
            }
          }
        }
        setUpdatedEvents(updatedResults);
      }
    } catch (error) {
      console.error("Error fetching calendars:", error);
    }
  };

  useEffect(() => {}, [updatedEvents]);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setUpdatedEvents([]);
    setIsModalOpen(false);
  };

  // State to hold edited values
  const [editedValues, setEditedValues] = useState({});
  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }
  const handleCellChange = (patientId, columnName, newValue, category) => {
    if (category === "userProcedures") {
      const treatment=treatments.find(item=>item._id===patient._id)
      if(treatment.review===true){
        toast.warn("Changes can't be made as treatment is finalized");
        return
      }
    }
    if (columnName != "custom" || columnName != "note") {
      // Update local state first
      setEditedValues((prevValues) => ({
        ...prevValues,
        [`${patientId}-${columnName}`]: newValue,
      }));
      props.updateEditedValues({ [`${patientId}-${columnName}`]: newValue });
    }

    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
      };
    }

    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const {
    patient,
    type,
    treatments,
    setTreatments,
    assigneeOptions,
    roomOptions,
    rowStates,
    toggleNoteField,
    getNoteIconColor,
    handleFocus,
    handleBlur,
    handleDeleteRow,
    handleDuplicateRow,
    handleOpenLabsModal,
    handleOpenProcedureModal,
    handleOpenDispenseModal,
    handleOpenTreatmentNoteModal,
    handleOpenVitalsModal,
    hideUserProcedures,
    userProcedureHeaders,
    visibleHeaders,
    hiddenEmptyColumns,
    HideNotes,
    hideUserDispensed,
    userDispensedHeaders,
    updateEditedValues,
    setCheckedLabs,
    setPreviousTreatmentId,
    onCopyClick,
    checkboxValues,
    setCheckboxValues,
    contextMenu,
    setContextMenu,
    newContextMenu,
    setNewContextMenu,
    handleClose,
    previousTreatmentId,
    handlePaste,
    handleUndo,
    undoOrderId,
    undoToken,
    currentTreatmentId,
    pastedTreatmentId,
    previouslyCheckedValues,
    setPasteAction,
    handleOpenCopyConfirmationDialog,
    setCopyKey,
    showContextMenu,
    setShowContextMenu,
  } = props;
  const statusOptions = ["scheduled", "arrived", "complete"];
  const typeOptions = ["Treatment", "Order"];

  const updateTreatmentWithEventDataTime = async (event, treatment) => {
    const eventDate = moment(event?.start?.dateTime).format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    const eventTime = moment(event?.start?.dateTime).format("hh:mm A");

    try {
      handleCellChange(treatment?._id, "event", event?.id, "patientInfo");
      handleCellChange(treatment?._id, "date", eventDate, "patientInfo");
      handleCellChange(treatment?._id, "time", eventTime, "patientInfo");
      const responseWatch = await axios.post(
        `/create-watch`,
        {
          eventId: event?.id,
          treatmentId: treatment?._id,
          calendarId: event?.calendarId,
          status: treatment?.status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toast.success("Event assigned to treatment");
    } catch (error) {
      // toast.error(error.message);
      console.error("Error creating treatment:", error);
    }
  };

  const getCheckedLabs = (userLabs) => {
    return userLabs.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  const tooltipLabs = getCheckedLabs(patient.userLabs);
  const tooltipDispensed = getDispensedItemsWithSelectedField(
    patient.userdispensed
  );
  const tooltipProcedures = getRelevantProcedures(patient.userProcedures);

  const getTooltipContent = (labs, dispensed, procedures) => (
    <div>
      {labs.length > 0 && (
        <div className="mb-4">
          <strong>Labs:</strong>
          {labs.map((lab) => (
            <div key={lab._id} className="my-2">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensed.length > 0 && (
        <div className={`mb-4 ${labs.length > 0 ? "mt-4" : ""}`}>
          <strong>Dispensed:</strong>
          {dispensed.map((item) => (
            <div key={item._id} className="my-2">
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {procedures.length > 0 && (
        <div
          className={`mb-4 ${
            labs.length > 0 || dispensed.length > 0 ? "mt-4" : ""
          }`}
        >
          <strong>Procedures:</strong>
          {procedures.map((procedure) => (
            <div key={procedure._id} className="my-2">
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const procedureTextNew = patient.userProcedures
    .filter(
      (procedure) =>
        procedure.checked ||
        procedure.custom ||
        (procedure.selectedField !== "" && procedure.dosages?.length > 0)
    )
    .map(
      (procedure) =>
        `${procedure.procedure_name}${procedure.custom ? " (Custom)" : ""}${
          procedure.selectedField ? ` (${procedure.selectedField})` : ""
        }`
    );

  const dispensedTextNew = patient.userdispensed
    .filter((dispensed) => dispensed.selectedField !== "")
    .map(
      (dispensed) =>
        `${dispensed.name}${dispensed.custom ? " (Custom)" : ""} (${
          dispensed.selectedField
        }, x${dispensed.quantity})`
    );

  const labsTextNew = patient.userLabs
    .filter((lab) => lab.checked)
    .map((lab) => `${lab.test_name}${lab.custom ? " (Custom)" : ""}`);

  const supplementsText =
    patient.userSupplements
      .filter((supplement) => supplement.name !== "" && supplement.quantity > 0)
      .map(
        (supplement) =>
          `${supplement.name} ${
            supplement?.selectedField ? `(${supplement?.selectedField})` : ""
          } (x${supplement.quantity})`
      )
      .join(", ") || "N/A";

  const [finalChecked, setFinalChecked] = useState(patient.review);
  const [selectOpen, setSelectOpen] = useState(false);

  function calculateTopPositionNCM(mouseY) {
    const menuHeight = newContextMenu ? 20 : 400;
    const windowHeight = window.innerHeight;
    const spaceBelowCursor = windowHeight - mouseY;

    if (spaceBelowCursor >= menuHeight) {
      return mouseY;
    } else {
      return mouseY - menuHeight;
    }
  }

  const handleContextMenu = (event, record) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      data: record,
    });
  };
  const handleOutsideClick = (event) => {
    if (!selectOpen) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectOpen]);

  const handleCopy = (treatment) => {
    setPreviousTreatmentId(treatment._id);
    onCopyClick(treatment);
    handleClose();
  };
  const onCheckboxChange = (changes) => {
    setCheckboxValues({ ...changes });
  };

  return (
    <TableRow
      sx={{
        backgroundColor: patient.type === "Order" ? "#ffd580" : "#FAFAFA", // Very light grey for order, almost white for other types
      }}
    >
      {/* Make the Date, Type, Room, and Note fields editable */}
      <TableCell class="Date-Container-Fix">
        <TextField
          type="date"
          value={
            editedValues[`${patient._id}-date`] || formatDate(patient.date)
          }
          onChange={(e) =>
            handleCellChange(patient._id, "date", e.target.value, "patientInfo")
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="text"
          value={editedValues[`${patient._id}-custom`] || patient.custom}
          onChange={(e) =>
            setEditedValues({
              ...editedValues,
              [`${patient._id}-custom`]: e.target.value,
            })
          }
          onBlur={(e) =>
            handleCellChange(
              patient._id,
              "custom",
              e.target.value,
              "patientInfo"
            )
          }
        />
      </TableCell>
      <TableCell>
        <Select
          value={editedValues[`${patient._id}-type`] || patient.type}
          onChange={(e) => {
            const newType = e.target.value;
            handleCellChange(patient._id, "type", newType, "patientInfo");
          }}
          renderValue={(selected) => selected}
        >
          {typeOptions.map((statusOption, index) => (
            <MenuItem key={index} value={statusOption}>
              {statusOption}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        {" "}
        <Checkbox
          checked={finalChecked}
          onChange={(e) => {
            setFinalChecked(e.target.checked);
            handleReviewChange(patient._id, e.target.checked);
            
          }}
        />
      </TableCell>
      <TableCell>
        {editedValues[`${patient._id}-type`] === "Order" ||
        patient.type === "Order" ? (
          // If the type is "Order", render an empty placeholder
          <Select disabled>
            <MenuItem value="">Select an option</MenuItem>
          </Select>
        ) : (
          // Otherwise, render the status Select with options
          <Select
            value={editedValues[`${patient._id}-status`] || patient.status}
            onChange={(e) => {
              const newStatus = e.target.value;
              handleCellChange(patient._id, "status", newStatus, "patientInfo");
            }}
            renderValue={(selected) => selected}
          >
            {statusOptions.map((statusOption, index) => (
              <MenuItem key={index} value={statusOption}>
                {statusOption}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>

      <TableCell>
        <Select
          value={editedValues[`${patient._id}-assignees`] || patient.assignees}
          onChange={(e) => {
            const valueArray = e.target.value; // This is already an array because it's a multi-select
            handleCellChange(
              patient._id,
              "assignees",
              valueArray,
              "patientInfo"
            );
          }}
          renderValue={(selected) => selected.join(", ")}
          multiple
        >
          {assigneeOptions.map(
            (
              assignee,
              index // Assuming 'options' is the array of all possible assignees
            ) => (
              <MenuItem key={index} value={assignee}>
                <Checkbox
                  checked={
                    editedValues.hasOwnProperty(`${patient._id}-assignees`)
                      ? editedValues[`${patient._id}-assignees`].includes(
                          assignee
                        )
                      : patient.assignees.includes(assignee)
                  }
                />
                {assignee}
              </MenuItem>
            )
          )}
        </Select>
      </TableCell>

      <TableCell>
        <Select
          value={editedValues[`${patient._id}-room`] || patient.room}
          onChange={(e) =>
            handleCellChange(patient._id, "room", e.target.value, "patientInfo")
          }
        >
          <MenuItem key="empty-option" value="">
            {"Empty"}
          </MenuItem>
          {roomOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </TableCell>

      {/* <TableCell key={patient._id}>
      <TextField
        type="text"
        value={editedValues[`${patient._id}-note`] || patient.note}
        onChange={(e) =>
          setEditedValues({
            ...editedValues,
            [`${patient._id}-note`]: e.target.value,
          })
        }
        onFocus={() => handleFocus(patient._id)}
        onBlur={handleBlur(patient)}
        InputProps={{
          style: {
            minWidth: "100px",
            width: rowStates[patient._id]?.isFocused
              ? calculateWidth(
                  editedValues[`${patient._id}-note`] || patient.note
                )
              : "100px",
          },
        }}
      />
    </TableCell> */}
      <TableCell key={patient._id}>
        {!rowStates[patient._id]?.showNoteField && (
          <IconButton onClick={() => toggleNoteField(patient._id)}>
            <NoteIcon
              style={{
                color: getNoteIconColor(
                  editedValues[`${patient._id}-note`] || patient.note
                ),
              }}
            />
          </IconButton>
        )}
        {rowStates[patient._id]?.showNoteField && (
          <TextField
            type="text"
            value={editedValues[`${patient._id}-note`] || patient.note}
            onChange={(e) =>
              setEditedValues({
                ...editedValues,
                [`${patient._id}-note`]: e.target.value,
              })
            }
            onFocus={() => handleFocus(patient._id)}
            onBlur={(e) => {
              handleBlur(patient)(e);
              toggleNoteField(patient._id);
            }}
            InputProps={{
              style: {
                minWidth: "100px",
                width: rowStates[patient._id]?.isFocused
                  ? calculateWidth(
                      editedValues[`${patient._id}-note`] || patient.note
                    )
                  : "100px",
              },
            }}
            autoFocus
          />
        )}
      </TableCell>

      <TableCell>
        <div className="buttonsarray">
          <IconButton
            color="default"
            onClick={() => handleDeleteRow(patient["_id"])}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
          <ToastContainer />
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            maxWidth="md"
            fullWidth
          >
            <Box p={1}>
              <DialogTitle>
                <div className="flex justify-between">
                  <p>Events</p>
                  <div>
                    <IconButton onClick={handlePreviousDate}>
                      <ChevronLeft />
                    </IconButton>
                    <DatePicker
                      selected={selectedDate}
                      value={selectedDate}
                      format="YYYY/MM/DD"
                      onChange={(date) => {
                        setSelectedDate(date);
                        // const parsedDate = new Date(date);

                        // // Extract year, month, and day from the Date object
                        // const year = parsedDate.getFullYear();
                        // const month = parsedDate.getMonth() + 1; // Month starts from 0
                        // const day = parsedDate.getDate();

                        // // Pad single-digit month and day with leading zeros if necessary
                        // const formattedMonth = month < 10 ? `0${month}` : month;
                        // const formattedDay = day < 10 ? `0${day}` : day;

                        // // Construct the desired date format
                        // const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
                        // // fetchCalendars(formattedDate);
                        //
                      }}
                    />
                    <IconButton onClick={handleNextDate}>
                      <ChevronRight />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                {" "}
                <div className="overflow-x-auto">
                  {updatedEvents.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Start Time
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Link
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {updatedEvents
                          .filter((event) => !event.order)
                          .map((event, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "bg-gray-50" : "bg-white"
                              }
                            >
                              <td className="px-6 py-4 whitespace-nowrap">
                                {event.summary}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {new Date(
                                  event.start.dateTime
                                ).toLocaleString()}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <a
                                  href={event.htmlLink}
                                  className="text-blue-500 hover:underline"
                                >
                                  Link
                                </a>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <Autocomplete
                                  id={`treatment-autocomplete-${index}`}
                                  options={treatments.filter(
                                    (option) =>
                                      option.event === "unassigned" ||
                                      !option.event
                                  )}
                                  style={{ width: 200 }}
                                  getOptionLabel={(option) => {
                                    const date = new Date(option["date"]);
                                    return date.toISOString().split("T")[0]; // Extracting only the date part
                                  }}
                                  // value={selectedPatients[index] || null}
                                  onChange={(_, newValue) => {
                                    updateTreatmentWithEventDataTime(
                                      event,
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <MuiTextField
                                      {...params}
                                      label="Select Treatment"
                                      variant="outlined"
                                    />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option["date"] === value["date"]
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No events found for selected date </p>
                  )}
                </div>
              </DialogContent>
            </Box>
          </Dialog>
          <Popover
            content={
              <div className="py-1 px-3 bg-white ">
                <MuiButton
                  variant="contained"
                  startIcon={<FileCopyIcon />}
                  title="Assign events to old treatments"
                  onClick={handleOpenModal}
                  sx={{
                    textTransform: "capitalize",
                  }}
                  fullWidth
                >
                  <span className="ml-2 text-base">Assign Events</span>
                </MuiButton>
                <Form
                  form={form}
                  onFinish={onFinish}
                  className="mt-4"
                  initialValues={{
                    all: true,
                    date: dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD"),
                    type: patient.type,
                  }}
                >
                  <Form.Item name="date" className="mb-2">
                    <DatePicker
                      defaultValue={dayjs(
                        moment().format("YYYY/MM/DD"),
                        "YYYY/MM/DD"
                      )}
                      className="w-full"
                    />
                  </Form.Item>

                  <p className="text-center text-md font-semibold">
                    What do you want to duplicate?
                  </p>
                  <div className="mt-2 flex flex-wrap">
                    <Form.Item
                      name="all"
                      valuePropName="checked"
                      className="mb-0 w-2/4"
                    >
                      <AntCheckbox
                        defaultChecked={true}
                        checked={allChecked}
                        onChange={() => setAllChecked(true)}
                      >
                        All
                      </AntCheckbox>
                    </Form.Item>
                    {patient.userLabs.some((lab) => lab.checked) && (
                      <Form.Item
                        name="labs"
                        valuePropName="checked"
                        className="mb-0 w-2/4"
                      >
                        <AntCheckbox
                          defaultChecked={false}
                          onChange={() => handleCheckboxChange("labs")}
                        >
                          Labs
                        </AntCheckbox>
                      </Form.Item>
                    )}
                    {patient.userProcedures.some(
                      (procedure) =>
                        (procedure.selectedField &&
                          procedure.selectedField.trim().length > 0) ||
                        procedure.checked
                    ) && (
                      <Form.Item
                        name="procedure"
                        valuePropName="checked"
                        className="mb-0 w-2/4"
                      >
                        <AntCheckbox
                          defaultChecked={false}
                          onChange={() => handleCheckboxChange("procedure")}
                        >
                          Procedures
                        </AntCheckbox>
                      </Form.Item>
                    )}
                    {patient.userdispensed.some(
                      (dispensed) =>
                        dispensed.selectedField &&
                        dispensed.selectedField.trim().length > 0
                    ) && (
                      <Form.Item
                        name="dispensed"
                        valuePropName="checked"
                        className="mb-0 w-2/4"
                      >
                        <AntCheckbox
                          defaultChecked={false}
                          onChange={() => handleCheckboxChange("dispensed")}
                        >
                          Dispensed
                        </AntCheckbox>
                      </Form.Item>
                    )}
                  </div>
                  <div>
                    <p className="font-semibold">Type:</p>
                    <Form.Item
                      name="type"
                      initialValue={patient.type}
                      className="mb-1"
                    >
                      <AntdSelect className="w-full">
                        {patient.type !== "Order" && (
                          <AntdSelect.Option value="Order">
                            Order
                          </AntdSelect.Option>
                        )}
                        {patient.type !== "Treatment" && (
                          <AntdSelect.Option value="Treatment">
                            Treatment
                          </AntdSelect.Option>
                        )}
                      </AntdSelect>
                    </Form.Item>
                  </div>
                  <Form.Item className="mt-4 mb-1">
                    <Space>
                      <Button
                        type="submit"
                        className="bg-blue-500 text-white"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                      <Button onClick={() => form.resetFields()}>Reset</Button>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            }
            trigger="click"
          >
            {" "}
            <IconButton
              onClick={() => form.resetFields()}
              color="default"
              aria-label="duplicate"
            >
              {" "}
              <FileCopyIcon />
            </IconButton>
          </Popover>
        </div>
      </TableCell>
      <TableCell>
        <div className="buttonsarray">
          <Tooltip title={getTooltipContent(tooltipLabs, [], [])}>
            <IconButton
              size="small"
              onContextMenu={(event) => {
                event.stopPropagation();
                setNewContextMenu(true);
                onCheckboxChange({
                  labs: true,
                  dispensed: false,
                  procedures: false,
                  supplements: false,
                  vitals: false,
                });
                setCopyKey("Labs");
                setCheckedLabs(getCheckedLabs(patient.userLabs));
                handleContextMenu(event, patient);
              }}
              onClick={() =>{

                handleOpenLabsModal(
                  patient.userLabs,
                  patient["_id"],
                  patient.date
                )
                props.setClickedTreatmentData(patient)
              }
              }
              aria-label="labs"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: patient.userLabs.some((lab) => lab.checked)
                  ? "#b9f6ca"
                  : "#e3f2fd", // Light green if any checked, else light blue
                margin: "2px",
                color: "black", // Text color
              }}
            >
              L
            </IconButton>
          </Tooltip>
          <Tooltip title={getTooltipContent([], [], tooltipProcedures)}>
            <IconButton
              size="small"
              onContextMenu={(event) => {
                event.stopPropagation();
                setNewContextMenu(true);
                onCheckboxChange({
                  labs: false,
                  dispensed: false,
                  procedures: true,
                  supplements: false,
                  vitals: false,
                });
                setCopyKey("Procedures");
                handleContextMenu(event, patient);
              }}
              onClick={() => handleOpenProcedureModal(patient["_id"])}
              aria-label="procedures"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: patient.userProcedures.some(
                  (procedure) =>
                    (procedure.selectedField &&
                      procedure.selectedField.trim().length > 0) ||
                    procedure.checked
                )
                  ? "#b9f6ca"
                  : "#90caf9", // Light green if any checked, else light blue

                margin: "2px",
                color: "black", // Text color
              }}
            >
              P
            </IconButton>
          </Tooltip>
          <Tooltip title={getTooltipContent([], tooltipDispensed, [])}>
            <IconButton
              size="small"
              onContextMenu={(event) => {
                event.stopPropagation();
                setNewContextMenu(true);
                onCheckboxChange({
                  labs: false,
                  dispensed: true,
                  procedures: false,
                  supplements: false,
                  vitals: false,
                });
                setCopyKey("Dispensed");
                handleContextMenu(event, patient);
              }}
              onClick={() => handleOpenDispenseModal(patient["_id"])}
              aria-label="dispense"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: patient.userdispensed.some(
                  (dispensed) =>
                    dispensed.selectedField &&
                    dispensed.selectedField.trim().length > 0
                )
                  ? "#b9f6ca"
                  : "#42a5f5", // Light green if any checked, else light blue

                margin: "2px",
                color: "black", // Text color
              }}
            >
              D
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            onClick={() => handleOpenTreatmentNoteModal(patient["_id"])}
            aria-label="TreatmentNote"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: patient.treatmentNotes.includes("Notes by:")
                ? "#81e984"
                : "#0e75e5",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            N
          </IconButton>
          <IconButton
            size="small"
            onContextMenu={(event) => {
              event.stopPropagation();
              setNewContextMenu(true);
              onCheckboxChange({
                labs: false,
                dispensed: false,
                procedures: false,
                supplements: false,
                vitals: true,
              });
              setCopyKey("Vitals");
              handleContextMenu(event, patient);
            }}
            onClick={() => handleOpenVitalsModal(patient["_id"])}
            aria-label="dispense"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                patient?.vitals?.length > 0 ? "#81e984" : "#1e88e5",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            V
          </IconButton>
        </div>
      </TableCell>

      {/* <TableCell style={{ width: "100px" }}>
        {labsTextNew.length > 0 && <span className=" font-bold">Labs:</span>}{" "}
        <br />{" "}
        {labsTextNew.map((lab, index) => {
          const isCustom = lab.includes("(Custom)");
          return (
            <span key={index} style={{ color: isCustom ? "blue" : "inherit" }}>
              {lab.replace(" (Custom)", "")}
              {index < labsTextNew.length - 1 ? ", " : ""}
            </span>
          );
        })}
        <br />{" "}
        {procedureTextNew.length > 0 && (
          <span className=" font-bold">Procedures:</span>
        )}{" "}
        <br />{" "}
        {procedureTextNew.map((procedure, index) => {
          const isCustom = procedure.includes("(Custom)");
          return (
            <span key={index} style={{ color: isCustom ? "blue" : "inherit" }}>
              {procedure.replace(" (Custom)", "")}
              {index < procedureTextNew.length - 1 ? ", " : ""}
            </span>
          );
        })}
        <>
          {" "}
          {dispensedTextNew.length > 0 && (
            <span className=" font-bold">Dispensed:</span>
          )}{" "}
          <br />{" "}
          {dispensedTextNew.map((dispensed, index) => {
            const isCustom = dispensed.includes("(Custom)");
            return (
              <span
                key={index}
                style={{ color: isCustom ? "blue" : "inherit" }}
              >
                {dispensed.replace(" (Custom)", "")}
                {index < dispensedTextNew.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </>
        {supplementsText === "N/A" ? (
          ""
        ) : (
          <p>
            {" "}
            <span className=" font-bold">Supplements:</span> <br />{" "}
            {supplementsText}
          </p>
        )}
      </TableCell> */}

      {/* {!hideVitals && (
      <>
        <TableCell>
          <TextField
            type="text"
            value={editedValues[`${patient._id}-HR`] || patient.vitals.HR}
            onChange={(e) =>
              setEditedValues({
                ...editedValues,
                [`${patient._id}-HR`]: e.target.value,
              })
            }
            onBlur={(e) =>
              handleCellChange(patient._id, "HR", e.target.value, "vitals")
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            value={editedValues[`${patient._id}-BP`] || patient.vitals.BP}
            onChange={(e) =>
              setEditedValues({
                ...editedValues,
                [`${patient._id}-BP`]: e.target.value,
              })
            }
            onBlur={(e) =>
              handleCellChange(patient._id, "BP", e.target.value, "vitals")
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            value={editedValues[`${patient._id}-T`] || patient.vitals.T}
            onChange={(e) =>
              setEditedValues({
                ...editedValues,
                [`${patient._id}-T`]: e.target.value,
              })
            }
            onBlur={(e) =>
              handleCellChange(patient._id, "T", e.target.value, "vitals")
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            value={editedValues[`${patient._id}-W`] || patient.vitals.W}
            onChange={(e) =>
              setEditedValues({
                ...editedValues,
                [`${patient._id}-W`]: e.target.value,
              })
            }
            onBlur={(e) =>
              handleCellChange(patient._id, "W", e.target.value, "vitals")
            }
          />
        </TableCell>
      </>
    )} */}
      {/* Dynamic data cells for userProcedures */}
      {!hideUserProcedures &&
        userProcedureHeaders.flatMap((header) => {
          if (
            !visibleHeaders.userProcedures.has(header) ||
            hiddenEmptyColumns.has(header)
          ) {
            return []; // Return empty if header is not visible
          }

          const userProcedureItem = patient.userProcedures.find(
            (procedure) => procedure.procedure_name === header
          );

          let cells = [];

          // Add cells for dosages, checked, and note based on the procedure item properties
          if (userProcedureItem) {
            // If the procedure has dosages, render the dosage dropdown
            if (
              userProcedureItem.dosages &&
              userProcedureItem.dosages.length > 0 &&
              "note" in userProcedureItem
            ) {
              cells.push(
                <TableCell key={`${patient._id}-${header}-dosages`}>
                  {/* Render dropdown for dosages */}
                  <Select
                    style={{
                      backgroundColor:
                        (!editedValues[`${patient._id}-date`] &&
                          isToday(new Date(patient.date))) || // Check if edited date is null and patient date is today
                        (editedValues[`${patient._id}-date`] &&
                          isToday(
                            new Date(editedValues[`${patient._id}-date`])
                          )) // Check if edited date exists and is today
                          ? "#27d128"
                          : patient.type === "Order"
                          ? "#f67de5"
                          : "#27d4f1",
                    }}
                    className={
                      editedValues[`${patient._id}-${header}-selectedField`] ||
                      userProcedureItem.selectedField
                        ? "selectWithValue"
                        : ""
                    }
                    value={
                      editedValues[`${patient._id}-${header}-selectedField`] ||
                      userProcedureItem.selectedField ||
                      ""
                    }
                    onChange={(e) => {
                      handleCellChange(
                        patient._id,
                        `${header}-selectedField`,
                        e.target.value,
                        "userProcedures"
                      );
                      userProcedureItem.selectedField = e.target.value;
                    }}
                  >
                    {/* Add this new MenuItem for the empty option */}

                    <MenuItem key="empty-option" value="">
                      {"Empty"}
                    </MenuItem>

                    {userProcedureItem.dosages.map((dosage) => (
                      <MenuItem key={dosage} value={dosage}>
                        {dosage}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              );

              // If the procedure can have a note, render the note input
              if (!HideNotes) {
                cells.push(
                  <TableCell key={`${patient._id}-${header}-note`}>
                    {/* Render input for note */}
                    <TextField
                      type="text"
                      value={
                        editedValues[`${patient._id}-${header}-note`] ||
                        userProcedureItem.note ||
                        ""
                      }
                      onChange={(e) =>
                        setEditedValues({
                          ...editedValues,
                          [`${patient._id}-${header}-note`]: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        handleCellChange(
                          patient._id,
                          `${header}-note`,
                          e.target.value,
                          "userProcedures"
                        )
                      }
                    />
                  </TableCell>
                );
              }
            }

            if (
              userProcedureItem.dosages &&
              userProcedureItem.dosages.length > 0 &&
              !("note" in userProcedureItem)
            ) {
              cells.push(
                <TableCell key={`${patient._id}-${header}-dosages`}>
                  {/* Render dropdown for dosages */}
                  <Select
                    style={{
                      backgroundColor:
                        (!editedValues[`${patient._id}-date`] &&
                          isToday(new Date(patient.date))) || // Check if edited date is null and patient date is today
                        (editedValues[`${patient._id}-date`] &&
                          isToday(
                            new Date(editedValues[`${patient._id}-date`])
                          )) // Check if edited date exists and is today
                          ? "#27d128"
                          : patient.type === "Order"
                          ? "#f67de5"
                          : "#27d4f1",
                    }}
                    className={
                      editedValues[`${patient._id}-${header}-selectedField`] ||
                      userProcedureItem.selectedField
                        ? "selectWithValue"
                        : ""
                    }
                    value={
                      editedValues[`${patient._id}-${header}-selectedField`] ||
                      userProcedureItem.selectedField ||
                      ""
                    }
                    onChange={(e) => {
                      handleCellChange(
                        patient._id,
                        `${header}-selectedField`,
                        e.target.value,
                        "userProcedures"
                      );
                      userProcedureItem.selectedField = e.target.value;
                    }}
                  >
                    <MenuItem key="empty-option" value="">
                      {"Empty"}
                    </MenuItem>
                    {userProcedureItem.dosages.map((dosage) => (
                      <MenuItem key={dosage} value={dosage}>
                        {dosage}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              );
            }
            // If the procedure has a 'checked' property, render the checkbox
            if (
              "checked" in userProcedureItem &&
              userProcedureItem.dosages.length === 0 &&
              !("note" in userProcedureItem)
            ) {
              cells.push(
                <TableCell key={`${patient._id}-${header}-checked`}>
                  <Checkbox
                    style={{
                      color:
                        (!editedValues[`${patient._id}-date`] &&
                          isToday(new Date(patient.date))) || // Check if edited date is null and patient date is today
                        (editedValues[`${patient._id}-date`] &&
                          isToday(
                            new Date(editedValues[`${patient._id}-date`])
                          )) // Check if edited date exists and is today
                          ? "#27d128"
                          : patient.type === "Order"
                          ? "#f67de5"
                          : "#27d4f1",
                    }}
                    checked={
                      editedValues[`${patient._id}-${header}-checked`] !==
                      undefined
                        ? editedValues[`${patient._id}-${header}-checked`]
                        : userProcedureItem.checked
                    }
                    onChange={(e) =>
                      handleCellChange(
                        patient._id,
                        `${header}-checked`,
                        e.target.checked,
                        "userProcedures"
                      )
                    }
                  />
                </TableCell>
              );
            }
            // If the procedure has a 'checked' property, render the checkbox
            if (
              "checked" in userProcedureItem &&
              userProcedureItem.dosages.length === 0 &&
              "note" in userProcedureItem
            ) {
              cells.push(
                <TableCell key={`${patient._id}-${header}-checked`}>
                  <Checkbox
                    style={{
                      color:
                        (!editedValues[`${patient._id}-date`] &&
                          isToday(new Date(patient.date))) || // Check if edited date is null and patient date is today
                        (editedValues[`${patient._id}-date`] &&
                          isToday(
                            new Date(editedValues[`${patient._id}-date`])
                          )) // Check if edited date exists and is today
                          ? "#27d128"
                          : patient.type === "Order"
                          ? "#f67de5"
                          : "#27d4f1",
                    }}
                    checked={
                      editedValues[`${patient._id}-${header}-checked`] !==
                      undefined
                        ? editedValues[`${patient._id}-${header}-checked`]
                        : userProcedureItem.checked
                    }
                    onChange={(e) =>
                      handleCellChange(
                        patient._id,
                        `${header}-checked`,
                        e.target.checked,
                        "userProcedures"
                      )
                    }
                  />
                </TableCell>
              );

              if (!HideNotes) {
                cells.push(
                  <TableCell key={`${patient._id}-${header}-note`}>
                    {/* Render input for note */}
                    <TextField
                      type="text"
                      value={
                        editedValues[`${patient._id}-${header}-note`] ||
                        userProcedureItem.note ||
                        ""
                      }
                      onChange={(e) =>
                        setEditedValues({
                          ...editedValues,
                          [`${patient._id}-${header}-note`]: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        handleCellChange(
                          patient._id,
                          `${header}-note`,
                          e.target.value,
                          "userProcedures"
                        )
                      }
                    />
                  </TableCell>
                );
              }
            }

            if ("customDosage" in userProcedureItem) {
              cells.push(
                <TableCell key={`${patient._id}-${header}-customDosage`}>
                  {/* Render input for customDosage */}
                  <TextField
                    type="text"
                    value={
                      editedValues[`${patient._id}-${header}-customDosage`] ||
                      userProcedureItem.customDosage ||
                      ""
                    }
                    onChange={(e) =>
                      setEditedValues({
                        ...editedValues,
                        [`${patient._id}-${header}-customDosage`]:
                          e.target.value,
                      })
                    }
                    onBlur={(e) =>
                      handleCellChange(
                        patient._id,
                        `${header}-customDosage`,
                        e.target.value,
                        "userProcedures"
                      )
                    }
                  />
                </TableCell>
              );
            }
          }

          return cells;
        })}

      {/* Dynamic data cells for userDispensedItems */}
      {!hideUserDispensed &&
        userDispensedHeaders.flatMap((header) => {
          if (
            !visibleHeaders.userDispensed.has(header) ||
            hiddenEmptyColumns.has(header)
          ) {
            return []; // Return empty if header is not visible
          }

          const userDispensedItem = patient.userdispensed.find(
            (item) => item.name === header
          );
          if (!HideNotes) {
            // Return cells for dosages, quantity, and note
            return [
              <TableCell key={`${patient._id}-${header}-dosages`}>
                {/* Render dropdown for dosages */}
                {userDispensedItem &&
                  userDispensedItem.dosages &&
                  userDispensedItem.dosages.length > 0 && (
                    <Select
                      style={{
                        backgroundColor:
                          (!editedValues[`${patient._id}-date`] &&
                            isToday(new Date(patient.date))) || // Check if edited date is null and patient date is today
                          (editedValues[`${patient._id}-date`] &&
                            isToday(
                              new Date(editedValues[`${patient._id}-date`])
                            )) // Check if edited date exists and is today
                            ? "#27d128"
                            : patient.type === "Order"
                            ? "#f67de5"
                            : "#27d4f1",
                      }}
                      className={
                        editedValues[
                          `${patient._id}-${header}-selectedField`
                        ] || userDispensedItem.selectedField
                          ? "selectWithValue"
                          : ""
                      }
                      value={
                        editedValues[
                          `${patient._id}-${header}-selectedField`
                        ] ||
                        userDispensedItem.selectedField ||
                        ""
                      }
                      onChange={(e) => {
                        handleCellChange(
                          patient._id,
                          `${header}-selectedField`,
                          e.target.value,
                          "userdispensed"
                        );
                        userDispensedItem.selectedField = e.target.value;
                      }}
                    >
                      <MenuItem key="empty-option" value="">
                        {"Empty"}
                      </MenuItem>
                      {userDispensedItem.dosages.map((dosage) => (
                        <MenuItem key={dosage} value={dosage}>
                          {dosage}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
              </TableCell>,
              <TableCell key={`${patient._id}-${header}-quantity`}>
                {/* Render input for quantity */}
                <TextField
                  type="number"
                  inputProps={{ min: "0" }} // Add this line
                  value={
                    editedValues[`${patient._id}-${header}-quantity`] ||
                    userDispensedItem?.quantity ||
                    0
                  }
                  onChange={(e) =>
                    handleCellChange(
                      patient._id,
                      `${header}-quantity`,
                      e.target.value,
                      "userdispensed"
                    )
                  }
                />
              </TableCell>,

              <TableCell key={`${patient._id}-${header}-note`}>
                {/* Render input for note */}
                <TextField
                  type="text"
                  value={
                    editedValues[`${patient._id}-${header}-note`] ||
                    userDispensedItem?.note ||
                    ""
                  }
                  onChange={(e) =>
                    setEditedValues({
                      ...editedValues,
                      [`${patient._id}-${header}-note`]: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    handleCellChange(
                      patient._id,
                      `${header}-note`,
                      e.target.value,
                      "userdispensed"
                    )
                  }
                />
              </TableCell>,
            ];
          } else {
            return [
              <TableCell key={`${patient._id}-${header}-dosages`}>
                {/* Render dropdown for dosages */}
                {userDispensedItem &&
                  userDispensedItem.dosages &&
                  userDispensedItem.dosages.length > 0 && (
                    <Select
                      style={{
                        backgroundColor:
                          (!editedValues[`${patient._id}-date`] &&
                            isToday(new Date(patient.date))) || // Check if edited date is null and patient date is today
                          (editedValues[`${patient._id}-date`] &&
                            isToday(
                              new Date(editedValues[`${patient._id}-date`])
                            )) // Check if edited date exists and is today
                            ? "#27d128"
                            : patient.type === "Order"
                            ? "#f67de5"
                            : "#27d4f1",
                      }}
                      className={
                        editedValues[
                          `${patient._id}-${header}-selectedField`
                        ] || userDispensedItem.selectedField
                          ? "selectWithValue"
                          : ""
                      }
                      value={
                        editedValues[
                          `${patient._id}-${header}-selectedField`
                        ] ||
                        userDispensedItem.selectedField ||
                        ""
                      }
                      onChange={(e) => {
                        handleCellChange(
                          patient._id,
                          `${header}-selectedField`,
                          e.target.value,
                          "userdispensed"
                        );
                        userDispensedItem.selectedField = e.target.value;
                      }}
                    >
                      <MenuItem key="empty-option" value="">
                        {"Empty"}
                      </MenuItem>
                      {userDispensedItem.dosages.map((dosage) => (
                        <MenuItem key={dosage} value={dosage}>
                          {dosage}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
              </TableCell>,
              <TableCell key={`${patient._id}-${header}-quantity`}>
                {/* Render input for quantity */}
                <TextField
                  type="number"
                  inputProps={{ min: "0" }} // Add this line
                  value={
                    editedValues[`${patient._id}-${header}-quantity`] ||
                    userDispensedItem?.quantity ||
                    0
                  }
                  onChange={(e) =>
                    handleCellChange(
                      patient._id,
                      `${header}-quantity`,
                      e.target.value,
                      "userdispensed"
                    )
                  }
                />
              </TableCell>,
            ];
          }
        })}
      {contextMenu?.data && showContextMenu && (
        <div
          className="fadeIn"
          style={{
            position: "fixed",
            top: calculateTopPositionNCM(contextMenu.mouseY),
            left: contextMenu.mouseX,
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            zIndex: 1000,
            padding: "8px 0",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ padding: "8px 16px", cursor: "pointer" }}>
            {newContextMenu && (
              <>
                <div
                  className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1 active:bg-blue-800  transition-colors duration-300 ease-in-out"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={() => {
                      handleCopy(contextMenu?.data);
                    }}
                    className="px-4"
                  >
                    Copy
                  </button>
                </div>
                {previousTreatmentId !== contextMenu?.data._id &&
                  previousTreatmentId !== null &&
                  JSON.stringify(checkboxValues) ==
                    JSON.stringify(previouslyCheckedValues) && (
                    <div
                      className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1 relative group active:bg-blue-800  transition-colors duration-300 ease-in-out"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button className="px-4 hover:text-white hover:bg-blue-500 rounded-sm ">
                        Paste
                      </button>
                      <div
                        style={{ borderRadius: "10px" }}
                        className="absolute hidden w-[150px] top-[-10px] left-[80px] bg-white shadow-lg py-2 px-4 group-hover:block"
                      >
                        <button
                          onClick={() => {
                            setPasteAction("modify");
                            setShowContextMenu(false);
                            handleOpenCopyConfirmationDialog();
                          }}
                          className="text-black hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left active:bg-blue-800  transition-colors duration-300 ease-in-out"
                        >
                          Merge
                        </button>
                        <button
                          onClick={() => {
                            setPasteAction("overwrite");
                            setShowContextMenu(false);
                            handleOpenCopyConfirmationDialog();
                          }}
                          className="text-black hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left active:bg-blue-800  transition-colors duration-300 ease-in-out"
                        >
                          Overwrite
                        </button>
                      </div>
                    </div>
                  )}
                {pastedTreatmentId == contextMenu?.data._id &&
                  JSON.stringify(checkboxValues) ==
                    JSON.stringify(previouslyCheckedValues) && (
                    <div
                      className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1 text-center active:bg-blue-800  transition-colors duration-300 ease-in-out"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className={`${
                          !undoOrderId && !undoToken ? "cursor-not-allowed" : ""
                        }`}
                        disabled={!undoOrderId && !undoToken}
                        onClick={() => handleUndo(undoOrderId, undoToken)}
                      >
                        Undo
                      </button>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      )}
    </TableRow>
  );
}
